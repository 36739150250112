import React from 'react';

import productImage from '../../assets/images/jackspot-product-app-xr.png';
import classes from './Product.module.css';

const product = (props: any) => (
    <div className={classes.Product} style={{height: props.height}}>
        <div className={classes.ProductPanel}>
            <p>Consulting and Development</p>
            <p>
                Media<br/>
                Entertainment<br/>
                Voice-driven Design<br/>
                Social Robotics<br/>
                AI Design<br/>
            </p>
        </div>
    </div>
);

export default product;
