import React, { Component } from 'react';
import Layout from './hoc/Layout/Layout';
import Content from './containers/Content/Content';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

export interface AppProps {}
export interface AppState {}

export default class App extends React.Component<AppProps, AppState> {

    render () {
     return (
       <div>
         <Layout>
            <Header />
            <Content />
            <Footer />
         </Layout>
       </div>
     );
   }
}
