import React from 'react';

import Logo from '../Logo/Logo'
import classes from './Header.module.css';


const toolbar = ( props: any ) => (
    <header className={classes.Header}>
        <div className={classes.Logo}>
            <Logo />
        </div>
    </header>
);

export default toolbar;
